export default {
  // ? ==============
  // ? === Layout ===
  // ? ==============

  navigation: {
    nav: {
      background: ['white', '', ''],
      height: ['auto', '', '', ''],
      color: 'text',
      padding: '0.25rem 1rem',
      '.hamburger': {
        border: 'solid 1px',
        borderRadius: '10px',
        borderColor: 'primary',
        height: '45px',
        width: '45px',
        p: '0.5rem',
        div: {
          backgroundColor: 'secondary'
        }
      },
      '.hamburgerOpen': {
        border: 'solid 1px',
        borderRadius: '10px',
        borderColor: 'primary',
        height: '45px',
        width: '45px'
      },
      '.logo': {
        position: 'static',
        transform: 'unset',
        img: {
          maxHeight: ['80px', '', '120px']
        }
      },
      '.logoScrolled': {
        position: 'static',
        display: 'flex',
        transform: 'unset',
        width: 'fit-content',
        a: {
          display: 'flex',
          width: 'fit-content',
          img: {
            maxHeight: ['65px', '', '80px']
          }
        }
      },
      '.navMenu': {
        alignItems: ['center', '', '', 'center'],
        justifyContent: ['center', '', '', 'flex-end'],
        padding: ['100px 0.5rem 80px', '', '', '1.5rem 1rem'],
        paddingLeft: ['', '', '', ''],
        flexWrap: 'wrap',
        backgroundColor: ['secondaryDarker', '', '', 'unset'],
        border: 'none',
        color: ['white', '', '', 'text'],
        '> .about': {
          display: 'none'
        },
        '.book-now': {
          margin: '0rem 0.5rem',
          '.ctaButton': {
            variant: 'buttons.secondary',
            margin: '0rem',
            padding: '0.75rem 1rem'
          }
        }
      },
      '.menuLogoContainer': {
        marginBottom: ['0.5rem !important', '1rem', '2rem'],
        img: {
          maxWidth: '170px',
          width: '100%'
        }
      },

      '.socialIconsContainer, .phoneContainer': {
        display: ['', '', '', 'none'],
        margin: ['4rem 0rem 0rem', '', '', '0rem 0rem 0rem 0.75rem'],
        padding: '1rem 0.5rem',
        borderRadius: '20px',
        backgroundColor: 'secondaryDark',
        color: 'white',
        a: {
          fontSize: '0.9rem'
        }
      },
      '.phoneContainer': {
        padding: '0rem',
        a: {
          padding: '1rem 2rem',
          fontSize: '0rem',
          svg: {
            fontSize: '1rem'
          },
          '::after': {
            fontSize: '1rem',

            content: "'Book Now'"
          }
        }
      },
      '.navItem': {
        margin: ['0.3rem', '', '', '0.6rem'],
        a: {
          color: ['inherit', '', '', 'inherit'],
          fontWeight: 'bold',
          letterSpacing: '1px',
          fontSize: ['1rem', '', '', '0.86rem'],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          // animation for nav items
          '::after': {
            content: '""',
            display: 'block',
            width: '0px',
            height: '2px',
            backgroundColor: 'primary',
            letterSpacing: '0.1em',
            textAlign: 'center',
            transition: '0.2s ease-in-out'
          },
          ':hover': {
            '::after': {
              color: 'primary',
              position: 'relative',
              left: '0px',
              width: '100%'
            }
          }
        }
      },
      '.navImage': {
        padding: ['', '', '', '', '0.2rem'],
        transition: 'all ease-in-out 0.3s',
        maxHeight: ['60px'],
        ':hover': {
          transform: 'rotate(5deg)'
        }
      },
      '.navBlock': {
        backgroundColor: 'rgba(30, 4, 4, 0.7)'
      }
    },

    '.navItemDropdown': {
      '.navItemDropdownMenu ': {
        padding: '1rem',
        width: 'auto',
        backgroundColor: ['secondaryDarker', '', '', 'white']
      },
      '.navItem': {
        a: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          svg: {
            marginLeft: '0.5rem'
          }
        }
      },
      '> .navItem': {
        a: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',

          svg: {
            marginLeft: '0.5rem'
          }
        }
      }
    }
  },

  footer: {
    backgroundColor: 'primaryDark',
    color: 'white',
    padding: ['2rem 0.5rem', '', '3rem 1.5rem', '', '3rem 4rem'],
    paddingBottom: ['9rem', '', '', '1rem'],
    maxWidth: 'unset',
    borderTop: '4px solid white',
    flexDirection: ['column', '', '', 'row'],
    '.image': {
      marginBottom: '1rem',
      filter: 'brightness(0) invert(1)',
      maxHeight: '60px'
    },
    '.multiButtonContainer': {
      mb: ['2rem', '', '', '0rem'],
      a: {
        color: 'primary',
        border: 'unset',
        letterSpacing: '1px',
        fontWeight: '900',
        fontSize: '0.8rem',
        padding: '0.25rem',
        ':hover': {
          color: 'white',
          filter: 'unset',
          backgroundColor: 'primary'
        }
      }
    },
    '.bottomBar': {
      borderRadius: '0px',
      borderColor: 'secondaryDark',
      borderWidth: '2px',
      padding: '1rem'
    },
    '.contactDetails-container': {
      textAlign: 'left',
      '.address, .phone': {
        textAlign: ['center', '', '', 'left'],
        svg: {
          display: ['none', '', '', 'inline-block']
        }
      }
    },
    '.socialContainer': {
      margin: '0.75rem',
      '.socialLink': {
        width: '50px',
        height: '50px',
        borderRadius: '100px',
        border: 'solid 2px white',
        ':hover': {
          backgroundColor: 'white'
        }
      },
      svg: {
        path: {
          fill: 'white'
        },
        height: '100%',
        width: '100%',

        padding: '8px',

        ':hover': {
          path: {
            fill: 'primary'
          }
        }
      }
    },
    '.gonationLogo': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 'none',
      svg: {
        filter: 'brightness(0) invert(1)',
        maxWidth: 'unset'
      },
      '.poweredByText': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
      }
    },
    '.quote': {
      fontStyle: 'unset',
      color: '#65c3f1',
      borderTop: 'solid 5px #e8e8e8',
      borderBottom: 'solid 5px #e8e8e8',
      textAlign: 'center'
    },
    '.column': {
      width: 'fit-content'
    },
    '.copyright': {
      fontSize: '0.7rem',
      width: ['100%', '', '', 'fit-content'],
      whiteSpace: 'break-spaces',
      textAlign: ['center', '', '', 'left'],
      marginBottom: ['1rem', '', '', '0rem']
    }
  },

  ctaWidget: {
    //   zIndex: '999',
    //   color: 'light',
    //   display: ['', '', '', ''],
    //   a: {
    //     display: 'flex',
    //     fontSize: '1rem'
    //   }
  },

  pageHero: {
    padding: '65vh 6% 6rem',
    backgroundColor: 'primary',
    height: '100vh',
    paddingBottom: '6rem',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    '.title': {
      color: 'primary',
      textAlign: 'left',
      padding: '1rem 2rem',
      backgroundColor: 'white',
      borderRadius: '0px 20px 20px 0px'
    },
    '::before': {
      content: "''",
      width: '5px',
      height: '35%',
      backgroundColor: 'white',
      position: 'absolute',
      left: '6%',
      bottom: '0rem'
    },

    '::after': {
      content: "''",
      borderLeft: '2px solid white',
      borderBottom: '2px solid white',
      transform: 'rotate(-45deg)',
      transformOrigin: 'center',
      width: '20px',
      height: '20px',
      position: 'absolute',
      left: 'calc(50% - 10px)',
      bottom: '1rem'
    }
  },

  // ? ==================
  // ? === reuseables ===
  // ? ==================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    fontWeight: 'bold',
    // textTransform: 'capitalize',
    color: 'secondaryDark'
  },

  subtitle: {
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    fontWeight: '600',
    color: 'primary'
  },

  subtext: {
    fontWeight: '400'
  },

  text: {
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '1.7',
    color: 'text'
  },

  homepageSideBySide1: {
    position: 'relative',
    backgroundColor: 'transparent',
    margin: ['5vh 0rem', '', '', '20vh 0rem'],
    zIndex: '2',

    '.lazyload-wrapper': {
      position: 'relative',
      borderRadius: '40px',
      overflow: 'hidden',
      borderRadius: '40px',
      boxShadow: '2px 2px 10px grey',
      border: ['14px solid'],
      borderColor: ['white']
    },
    flexDirection: ['column', '', 'row'],
    padding: ['1rem', , '2rem 2rem 4rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      marginBottom: '1.5rem'
    },
    '.content': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ['2rem 1rem', '', '2rem', '4rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '::before': {
      position: 'absolute',
      width: '50vw',
      content: "''",
      height: '50vw',
      borderRadius: '500px',
      backgroundColor: 'primaryLight',
      zIndex: '-1',
      right: '-25vw',
      bottom: '-10vw'
    }
  },

  homepageSideBySide2: {
    variant: 'customVariants.homepageSideBySide1',
    '.content': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: ['2rem 1rem', '', '2rem', '4rem']
    },
    '::after': {
      position: 'absolute',
      width: '50vw',
      content: "''",
      height: '50vw',
      borderRadius: '500px',
      backgroundColor: 'primaryLight',
      zIndex: '-1',
      left: '-25vw',
      top: ['-20vw', '-10vw'],
      zIndex: '-1'
    }
  },

  // ? =================
  // ? === homepage ====
  // ? =================

  homepageHero: {
    color: 'white',
    zIndex: '2',
    '.hero_content_container': {
      maxWidth: '1200px',
      top: 'calc(50% + 50px)'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '2.5rem', '4rem', '4.5rem'],

      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      position: 'absolute',
      bottom: '0rem',

      color: 'white',
      p: {
        fontSize: ['0.9rem', '1rem']
      },
      mb: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },
    '.slick-slider .slick-slide img': {
      objectPosition: ['34%', '', '30%', '20%', 'center'],
      filter: ['brightness(0.9)']
    },
    '.slick-dots': {
      display: 'none !important'
    },

    '.slick-arrow': {
      display: 'none !important'
    }
  },
  homepageHeroBar: {
    position: 'absolute',
    top: '100vh',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%,-100%)',

    zIndex: '100',
    color: 'white',
    '.content': {
      border: 'none'
    }
  },

  homepageBar: {
    backgroundColor: 'primary',
    color: 'white',
    '.content': {
      border: 'none',
      padding: '0rem',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.25rem', '', '1.5rem'],
        m: '0rem',
        color: 'inherit'
      }
    },
    '.ctaContainer': {
      padding: '0rem'
    }
  },

  shoutSection: {
    flexDirection: ['column', 'column', 'row', ''],
    alignItems: ['flex-start', '', '', 'center'],
    position: 'relative',
    padding: ['2rem 1rem', '', '7vh 2rem', '7vh 3rem', '7vh 10vw', '7vh 15vw'],
    overflow: 'hidden',
    // margin: '2rem 0rem',
    backgroundColor: 'primaryDark',
    '::after': {
      content: "''",
      backgroundColor: 'primary',
      width: '50%',
      height: '100%',
      position: 'absolute',
      zIndex: '-1',
      right: '0rem'
    },
    '.imageContainer': {
      width: ['100%', '100%', '40%', '25%'],
      border: 'unset',
      border: ['13px solid'],
      borderColor: ['primary'],
      borderRadius: ['30px', '', '', '30px'],
      overflow: 'hidden',
      zIndex: '3',
      marginBottom: ['1rem', '', '0rem'],
      '.imageFill': {
        paddingBottom: '100%'
      }
    },
    '.content': {
      padding: ['1rem', '', '2rem', '2rem 2rem 2rem 6rem', '2rem 2rem 2rem 6rem'],
      width: ['100%', '100%', '40%', '75%'],
      alignItems: 'flex-start',
      color: 'white',
      position: 'relative'
    },
    '.title': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      fontSize: ['1.25rem', '1.5rem'],
      color: 'inherit',
      margin: '0rem 0rem 0rem',
      mb: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text',
      maxWidth: 'unset',
      margin: '0rem',
      lineHeight: ['1.5', '1.5'],
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      color: 'inherit'
    },

    '.date': {
      order: 3,
      fontSize: '1rem',
      maxWidth: 'unset',
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'secondary'
    },

    '.shoutCTABtns': {
      order: 4
    },
    '.shoutCTA': {
      variant: 'buttons.primary',
      margin: '1rem 1rem 1rem 0rem'
    }
  },

  // ? ========================
  // ? === content Sections ===
  // ? ========================

  homepageQuote: {
    variant: 'customVariants.homepageContentSection1'
  },

  homepageContentSection1: {
    my: '10vh',
    padding: '20vh 2rem 20vh',
    backgroundColor: 'white',
    textAlign: 'center',
    '.section': {
      maxWidth: '1100px'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      mb: '2rem',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      mb: '2rem',
      order: '2'
    },
    '.text': {
      variant: 'customVariants.text',
      order: '3',
      mb: '2rem'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? =================
  // ? === About Us ====
  // ? =================

  about1: {
    variant: 'customVariants.homepageSideBySide1',
    margin: ['5vh 2.5vw'],
    padding: ['', '', '0rem', '3rem'],
    'div.content': {
      width: ['100%', '', '55%', '60%']
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '45%', '40%'],
      height: 'fit-content',
      img: {
        objectFit: 'contain'
      }
    }
  },

  about2: {
    variant: 'customVariants.homepageSideBySide1',
    position: 'relative',
    top: ['0px', '', '-100px', '-240px'],
    margin: ['0vh 2.5vw 5vh'],
    padding: ['', '', '0rem', '3rem'],
    '::before': {
      content: 'none'
    },
    'div.content': {
      width: ['100%', '', '55%', '60%'],
      flexGrow: '1'
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '45%', '40%'],
      height: 'fit-content',
      img: {
        objectFit: 'contain'
      }
    }
  },

  // ? =======================
  // ? === About Services ====
  // ? =======================

  aboutServices1: {
    variant: 'customVariants.homepageSideBySide1'
  },
  aboutServices2: {
    variant: 'customVariants.homepageSideBySide2'
  },

  // ? ==================
  // ? ===  Services ====
  // ? ==================

  services1: {
    variant: 'customVariants.homepageContentSection1',
    padding: '5vh 1rem 5vh'
  },
  services2: {
    variant: 'customVariants.services1'
  },
  services3: {
    variant: 'customVariants.homepageSideBySide1'
  },
  services4: {
    variant: 'customVariants.homepageSideBySide2'
  },
  services5: {
    variant: 'customVariants.services1'
  },

  // ? ===================
  // ? === Treatments ====
  // ? ===================

  treatments1: {
    variant: 'customVariants.homepageSideBySide1',
    'div.lazyload-wrapper': {
      height: 'fit-content',
      img: {
        objectFit: 'contain'
      }
    }
  },
  treatments2: {
    variant: 'customVariants.homepageSideBySide2',
    'div.lazyload-wrapper': {
      height: 'fit-content',
      img: {
        objectFit: 'contain'
      }
    }
  },

  // ? ==================
  // ? === Our office ===
  // ? ==================

  adjustmentBoxes: {
    '.box': {
      flexGrow: '1',
      borderRadius: '0px',
      width: ['100%', '100%', '49%', '48%', '22%'],
      '.image': {
        mb: '2rem',
        height: '40vh',
        objectFit: 'cover'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '', '1.75rem'],
        padding: '0rem',
        mb: '2rem',
        order: '1'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        mb: '2rem',
        order: '2'
      },
      '.text': {
        variant: 'customVariants.text',
        order: '3',
        mb: '2rem'
      }
    }
  },

  // ? ==================
  // ? === Promotions ===
  // ? ==================

  promotions: {
    variant: 'customVariants.homepageContentSection1',
    padding: '5vh 1rem 5vh',
    '.eventTime, .eventItemDescription': {
      textAlign: 'left'
    },

    '.eventItemDateContainer, .eventTime': {
      display: 'none'
    },
    '.eventCTABtns': {
      a: {
        variant: 'buttons.primary',
        width: 'fit-content'
      }
    },
    '.container': {
      '> .title': {
        display: 'none'
      }
    }
  },

  // ? ===============
  // ? === gallery ===
  // ? ===============

  gallery: {
    '.albumImage': {
      border: 'solid 5px white',
      boxShadow: '2px 2px 10px grey',
      borderRadius: '20px'
    }
  },

  // ? ===============
  // ? === Contact ===
  // ? ===============

  locationMap: {
    '.content_container': {
      backgroundColor: '#e6f7ff',
      color: 'black'
    },
    '.title': {
      color: 'primary',
      textTransform: 'uppercase'
    }
  },

  contact1: {
    variant: 'customVariants.homepageContentSection1',
    padding: '5vh 1rem 5vh'
  },

  contactForm: {
    padding: '5rem 1rem',
    backgroundColor: 'white',
    order: '6',
    color: 'white',
    h2: {
      variant: 'customVariants.title',
      marginBottom: '2rem',
      color: 'white',
      fontSize: ['2rem', '', '2.5rem'],
      borderBottom: '1px solid'
    },
    '.text': {
      mb: '2rem'
    },
    '.textField, .inputField': {
      borderColor: 'primary',
      color: 'text',
      borderRadius: '20px',
      padding: '1.5rem',
      '::placeholder': {
        color: 'white'
      }
    },
    '.submitBtn': {
      variant: 'buttons.primary'
    }
  }
}
